/* global Office */

import meetingTypes from "./meetingTypes";

import { dateDifferenceInMinutes, getEndTime, getStartTime, showMessage } from "./utils";

import { parsePrimaryMeeting } from "./parseMeeting";
import { createMeetings, getMeetingByUri } from "../api";

const pcTableUUID = "f481e4fa0b1c4d27a2b250250a5cb747";

function bodyMeetingHtml(meeting) {
  console.log("Meeting type: " + meeting.meetingType);

  const meetingMetadata = meetingTypes[meeting.meetingType];

  if (!meetingMetadata) {
    return null;
  }

  console.log(meetingMetadata);

  const sipParagraph = () => {
    if (meeting.details?.sipAddress) return `<p>${meeting.details?.sipAddress}</p>`;
    return "";
  };

  return `
  <td style="text-align: center; padding: 12px; padding-top: 22px; background: #e7e7e7; border-style: solid; border-width: 6px; border-color: white; display: flex;" class="fireball-meeting">
    <a style="color: black; text-align: center;" href="${meetingMetadata.hrefFunction(meeting.uri)}">
      <img width="40" height="40" src="${meetingMetadata.icon}"/>
      <p>Join ${meetingMetadata.name} meeting</p>
      ${sipParagraph()}
    </a>
  </td>`;
}

function bodyMeetingsHtml(meetingsData) {
  const meetings = meetingsData?.externalMeetings.filter((m) => !m.isPrimary);

  console.log(meetings);

  return `
  <table style="padding: 20px; padding-left: 8px; font-family: 'Segoe UI';" id="fireball-meetings">
    <tr>
      <td colspan="${meetings.length}">
        <p style="width: 100%; text-align: left; font-size: 18px;">Cross platform meetings powered by PhasedConnect</p>
      </td>
    </tr>
    <tr>
      ${meetings.reduce((acc, m) => acc + bodyMeetingHtml(m), "")}
    </tr>
    <tr style="color: white;">
      <td colspan="${meetings.length}">
        <p>${pcTableUUID}</p>
      </td>
    </tr>
  </table>`;
}

function updateBody(mailboxItem, meetingData, event, existingBody) {
  console.log(mailboxItem);

  var bodyHtml = document.createElement("html");
  bodyHtml.innerHTML = existingBody;

  const allTables = bodyHtml.getElementsByTagName("table");
  console.log(allTables);
  const pcTable = Array.from(allTables || []).find(
    (t) => t.id === "fireball-meetings" || t.innerText.includes(pcTableUUID)
  );
  console.log("Will remove:");
  console.log(pcTable);

  if (pcTable) {
    pcTable.remove();
    console.log("After removal");
    console.log(bodyHtml);
  }

  const bodyWithMeetingInfo = bodyMeetingsHtml(meetingData); //  pcTable; // existingBody + currentMeetings; //html bodyMeetingsHtml(meetingData),

  console.log("html");

  console.log(bodyWithMeetingInfo);

  if (!Office.context.mailbox.item.body.setAsync) {
    showMessage("Couldn't get permission to to edit the event body. Make sure you own the event and are in edit mode.");
    event?.completed();
    return;
  }

  Office.context.mailbox.item.body.setAsync(
    bodyHtml.innerHTML + bodyWithMeetingInfo,
    { asyncContext: event, coercionType: "html" },
    function (setBodyResult) {
      console.log("Set body res");
      console.log(setBodyResult);
      if (setBodyResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log("Scueeded setting body");
        event?.completed();
        // setBodyResult.asyncContext?.completed?.({ allowEvent: true });
      } else {
        console.error("Failed to set HTML body.");
        event?.completed();
        // setBodyResult.asyncContext?.completed?.({ allowEvent: false });
      }
      console.log("Done");
    }
  );
}

function appendContent(meetingData, event) {
  const mailboxItem = Office.context.mailbox.item;

  mailboxItem.body.getAsync("html", { asyncContext: event }, function (getBodyResult) {
    console.log(getBodyResult);
    if (getBodyResult.status === Office.AsyncResultStatus.Succeeded) {
      console.log("Succeeded");
      updateBody(mailboxItem, meetingData, event, getBodyResult.value);
    } else {
      console.error("Failed to get HTML body.");
    }
  });
}

export async function createOtherMeetingsForCurrentMeeting(event) {
  const finalize = (returnValue) => {
    if (event) {
      event.completed?.();
    }
    return returnValue;
  };

  const activeItem = Office.context.mailbox.item;

  if (!activeItem) {
    return finalize(false);
  }

  console.log(activeItem);

  activeItem.body.getAsync("html", { asyncContext: "context" }, async (r) => {
    console.log(r.value);
    let primaryMeeting = parsePrimaryMeeting(r.value);

    if (!primaryMeeting?.key) {
      showMessage(
        "There doesn't seem to be a usable virtual meeting URL in the event body. Make sure the body isn't empty or try to create your meeting using manually using the Advanced Meeting screen."
      );
      return finalize(false);
    }

    try {
      const res = await getMeetingByUri(primaryMeeting.uri);
      const mData = res?.data;

      if (mData) {
        showMessage(
          "There's already a meeting created for this primary meeting's URL. Open the taskpane to inspect it."
        );

        appendContent(mData, event);

        return false;
      }
    } catch {
      console.log("Proceeding");
    }

    try {
      //TODO find start and end time
      const startTime = await getStartTime();
      const endTime = await getEndTime();
      const durationMinutes = dateDifferenceInMinutes(startTime, endTime);
      const res = await createMeetings(primaryMeeting, startTime, durationMinutes);
      const meetingData = res.data;

      console.log("mdta", meetingData);

      appendContent(meetingData, event);

      showMessage("Created meetings!");

      return true;
    } catch {
      showMessage("Couldn't create meetings. Try opening the takspane and logging in.");
    }

    return finalize(false);
  });
}
