/* global Office */

import * as React from "react";

import { Label } from "@fluentui/react/lib/Label";
import { TextField } from "@fluentui/react/lib/TextField";

import { Checkbox, DatePicker, Stack, TimePicker, Spinner } from "@fluentui/react";

import { Button } from "../components/Button/Button";
import { ErrorText } from "../components/ErrorText";

import meetingTypes, { matchMeeting } from "../meetingTypes";

import { createMeetings } from "../../api";
import { dateDifferenceInMinutes, setTimeForDate } from "../utils";

const stackTokens = { childrenGap: 10 };

function addMinutesToDate(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export const CreatePane = ({ onCreate }) => {
  const [primaryUri, setPrimaryUri] = React.useState(null);
  const [meetingDate, setMeetingDate] = React.useState(new Date());
  console.log("meetingDate", meetingDate);
  // const [meetingDuration, setMeetingDuration] = React.useState(45);
  // console.log("meetingDuration", meetingDuration);
  const [creationStatus, setCreationStatus] = React.useState(null);

  const [meetingStartTime, setMeetingStartTime] = React.useState(new Date());
  const [meetingEndTime, setMeetingEndTime] = React.useState(addMinutesToDate(new Date(), 45));
  console.log("meetingStartTime", meetingStartTime);
  console.log("meetingEndTime", meetingEndTime);

  const [platforms, setPlatforms] = React.useState(
    Object.fromEntries(Object.keys(meetingTypes).map((m) => [m, Office.context.roamingSettings.get("use-" + m)]))
  );

  console.log(platforms);

  const onMeetingChange = (e) => {
    setPlatforms({ ...platforms, [e.target.id]: e.target.checked });
  };

  const onSubmit = async () => {
    const meetingKey = matchMeeting(primaryUri);

    if (!meetingKey) {
      setCreationStatus("input-error");
    }

    setCreationStatus("creating");

    const meetingStartDateTime = setTimeForDate(meetingDate, meetingStartTime);
    const res = await createMeetings(
      { key: meetingKey, uri: primaryUri },
      meetingStartDateTime,
      dateDifferenceInMinutes(meetingStartDateTime, meetingEndTime)
    );

    setCreationStatus("created");

    setTimeout(onCreate, 500);

    console.log(res);
  };

  const control = () => {
    if (creationStatus === "created") {
      return <p style={{ textAlign: "center" }}>Meeting created succesfully</p>;
    }

    if (creationStatus === "creating") return <Spinner />;

    return (
      <Button onClick={onSubmit} style={{ margin: "auto", marginTop: 20 }}>
        Create
      </Button>
    );
  };

  return (
    <div className="pane overview-pane">
      <h2>Create new multiplatform meeting</h2>

      <Label htmlFor="token">Primary meeting URL</Label>
      <TextField id="primaryUrl" value={primaryUri} onChange={(e) => setPrimaryUri(e.target.value)} />
      {creationStatus === "input-error" && <ErrorText>Invalid meeting URL</ErrorText>}

      <Label htmlFor="token">Meeting platforms</Label>
      <Stack tokens={stackTokens}>
        {Object.entries(meetingTypes).map(([k, v]) => (
          <Checkbox key={k} id={k} label={v.name} checked={platforms[k]} onChange={onMeetingChange} />
        ))}

        <Label htmlFor="meeting-date">Meeting date</Label>
        <DatePicker
          id="meeting-date"
          name="meeting-date"
          onSelectDate={(date) => setMeetingDate(date)}
          value={meetingDate}
        />
        <Label htmlFor="meeting-start-time">Meeting start time</Label>
        <TimePicker
          increments={1}
          id="meeting-start-time"
          name="meeting-start-time"
          onChange={(target, startTime) => setMeetingStartTime(startTime)}
          defaultValue={meetingStartTime}
        />
        <Label htmlFor="meeting-end-time">Meeting end time</Label>
        <TimePicker
          increments={1}
          id="meeting-end-time"
          name="meeting-end-time"
          onChange={(target, endTime) => setMeetingEndTime(endTime)}
          defaultValue={meetingEndTime}
        />

        {control()}
      </Stack>
    </div>
  );
};
